import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, ListSubheader, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Image from '../../Image';
// hooks
import useLocales from '../../../hooks/useLocales';

import { useDispatch, useSelector } from '../../../redux/store';
import { setActiveTab } from '../../../redux/slices/game';
import { openLoginSignup } from '../../../redux/slices/authPages';

import useResponsive from '../../../hooks/useResponsive';
import Iconify from '../../Iconify';
import palette from '../../../theme/palette';
import LanguagePopover from '../../../layouts/dashboard/header/LanguagePopover';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';
import useGameNav from '../../../hooks/useGameNav';
import useAuth from '../../../hooks/useAuth';
import { IconFilter } from '../../../config';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
  onCloseSidebar: PropTypes.func,
};

const ButtonContainer = styled(Stack)(({ theme }) => ({
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
  height: '43px',
  alignItems: 'center',
  cursor: 'pointer',
}));

const StyledImage = styled(Image)(({ theme }) => ({
  width: 38,
  height: 'auto',
  paddingLeft: 8,
}));

const NavWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '10px',
  cursor: 'pointer',
  padding: '5px 8px',
  position: 'relative',
  height: '100%',
}));

const NavImage = styled(Box)(({ theme }) => ({
  width: '36px',
  height: '36px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const NavBtnText = styled(Typography)(() => ({
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5',
  // textAlign: 'center',
  textDecoration: 'none',
  color: palette.navBarVertical.menu.color,
  marginLeft: '16px',
}));

export default function NavSectionVertical({ navConfig, isCollapse = false, onCloseSidebar = () => {}, ...other }) {
  const { logout } = useAuth();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { translate } = useLocales();

  const { changeTab } = useGameNav();

  const { gameCategsAndCompanies, activeTab, angPauPromos, promotions } = useSelector((x) => ({
    ...x.game,
    ...x.lookup,
  }));

  const isDesktop = useResponsive('up', 'lg');

  const isAuthenticated = localStorage.getItem('accessToken');

  const [referralDepositBonusId, setReferralDepositBonusId] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const referralDepositBonusType = promotions?.data?.filter((f) => f.promotion_type === 'referral_deposit_bonus');

    if (referralDepositBonusType?.length > 0) {
      setReferralDepositBonusId(referralDepositBonusType[0]?.id);
    }
  }, [promotions]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const makeGameCategory = (_category) => {
    let nav = '';

    if (_category?.slug.toLowerCase().indexOf('slots') !== -1)
      nav = { src: require('../../../assets/menu/nav-slot.png') };

    if (_category?.slug.toLowerCase().indexOf('live-casino') !== -1)
      nav = { src: require('../../../assets/menu/nav-live.png') };

    if (_category?.slug.toLowerCase().indexOf('fishing') !== -1)
      nav = { src: require('../../../assets/menu/nav-fish.png') };

    if (_category?.slug.toLowerCase().indexOf('card') !== -1)
      nav = { src: require('../../../assets/menu/nav-card.png') };

    if (_category?.slug.toLowerCase().indexOf('sports') !== -1)
      nav = { src: require('../../../assets/menu/nav-sport.png') };

    if (_category?.slug.toLowerCase().indexOf('crash-game') !== -1)
      nav = { src: require('../../../assets/menu/nav-crash.png') };

    if (_category?.slug.toLowerCase().indexOf('e-sports') !== -1) nav = { icon: 'streamline:esports-solid' };

    if (_category?.slug.toLowerCase().indexOf('instant-game') !== -1) nav = { icon: 'ion:rocket-sharp' };

    if (_category?.slug.toLowerCase().indexOf('exchange') !== -1)
      nav = { src: require('../../../assets/menu/nav-exchange.png') };

    return (
      <NavWrapper
        key={_category?.key}
        onClick={() => {
          if (!isDesktop) onCloseSidebar();
          navigate(`/games/${_category?.slug}`);
        }}
        // sx={activeTab === _category?.slug ? { backgroundColor: 'background.paper' } : {}}
      >
        <NavImage>
          {nav?.src ? (
            <Image
              src={nav.src}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                filter: IconFilter,
                height: '36px',
                width: '36px',
              }}
            />
          ) : (
            <></>
          )}
          {nav?.icon ? (
            <Iconify icon={nav.icon} sx={{ width: '30px', height: '30px', color: palette.home.iconColor }} />
          ) : (
            <></>
          )}
        </NavImage>
        <NavBtnText>{_category?.name}</NavBtnText>
      </NavWrapper>
    );
  };

  return (
    <Box {...other}>
      <Stack sx={{ px: 1, pb: 1 }} spacing={1}>
        {/* {angPauPromos?.data?.length ? (
          <Box onClick={() => navigate('/angpau')}>
            <Image src={require('../../../assets/menu/nav-earn.png')} />
          </Box>
        ) : (
          ''
        )} */}

        <NavWrapper onClick={() => navigate('/home')}>
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-home.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                height: '25px',
                width: '25px',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('home')}</NavBtnText>
        </NavWrapper>

        {/* <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('/vip');
          }}
        >
          <NavImage>
            <Iconify icon={'quill:vip'} sx={{ color: palette.home.iconColor, width: '100%', height: '100%' }} />
          </NavImage>
          <NavBtnText>{translate('VIP Club')}</NavBtnText>
        </NavWrapper> */}

        {gameCategsAndCompanies.length > 0 &&
          gameCategsAndCompanies.map((item, index) => <Box key={index}>{makeGameCategory(item)}</Box>)}

        <NavWrapper onClick={() => navigate('/promotion')}>
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-promo.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                height: '25px',
                width: '25px',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('promotions')}</NavBtnText>
        </NavWrapper>

        <NavWrapper onClick={() => navigate('/betting_pass')}>
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-betting-pass.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                height: '25px',
                width: '25px',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('betting_pass')}</NavBtnText>
        </NavWrapper>

        <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('user/wallet');
            onCloseSidebar();
          }}
        >
          <NavImage sx={{ justifyContent: 'center' }}>
            <Iconify
              icon={'mdi:wallet-plus'}
              sx={{ color: palette.home.iconColor, width: '25px', height: '25px', display: 'block', mx: 'auto' }}
            />
          </NavImage>
          <NavBtnText>{translate('deposit')}</NavBtnText>
        </NavWrapper>

        <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('/vip');
            // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
            onCloseSidebar();
          }}
        >
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-vip.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                height: '33px',
                width: '33px',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('vip')}</NavBtnText>
        </NavWrapper>

        <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('/referral');
            // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
            onCloseSidebar();
          }}
        >
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-referral.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                height: '33px',
                width: '33px',
              }}
            />
          </NavImage>
          <NavBtnText>{translate('referral')}</NavBtnText>
        </NavWrapper>
        {/* <Image src={require('../../../assets/menu/refer.png')} sx={{ width: '100%', height: 'auto' }} /> */}
        {isAuthenticated ? (
          <>
            <NavWrapper
              onClick={() => {
                navigate('/history/bet');
                if (!isDesktop) onCloseSidebar();
              }}
            >
              <NavImage>
                <Iconify
                  icon={'material-symbols:history'}
                  sx={{ color: palette.home.iconColor, width: '30px', height: '30px' }}
                />
              </NavImage>
              <NavBtnText>{translate('game_history')}</NavBtnText>
            </NavWrapper>
            <NavWrapper
              onClick={() => {
                navigate('/history/transaction');
                if (!isDesktop) onCloseSidebar();
              }}
            >
              <NavImage>
                <Iconify
                  icon={'grommet-icons:transaction'}
                  sx={{ color: palette.home.iconColor, width: '25px', height: '25px' }}
                />
              </NavImage>
              <NavBtnText>{translate('transaction_history')}</NavBtnText>
            </NavWrapper>
            <NavWrapper onClick={handleLogout}>
              <NavImage>
                <Iconify
                  icon={'clarity:logout-line'}
                  sx={{ color: palette.home.iconColor, width: '25px', height: '25px' }}
                />
              </NavImage>
              <NavBtnText>{translate('logout')}</NavBtnText>
            </NavWrapper>
          </>
        ) : (
          <></>
        )}

        <LanguagePopover
          CustomBtn={
            <NavWrapper>
              <NavImage>
                <Image
                  src={require('../../../assets/menu/nav-lang.png')}
                  sx={{
                    mx: 'auto',
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                  }}
                />
              </NavImage>
              <NavBtnText>{translate('language')}</NavBtnText>
            </NavWrapper>
          }
        />

        {/* <NavWrapper
          onClick={() => {
            if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
            else navigate('/referral');
            // if (referralDepositBonusId) navigate(`/promotion/view/${referralDepositBonusId}`);
            onCloseSidebar();
          }}
        >
          <NavImage>
            <Image
              src={require('../../../assets/menu/nav-lang.png')}
              sx={{
                mx: 'auto',
                cursor: 'pointer',
                filter:
                  IconFilter,
              }}
            />
          </NavImage>
          <NavBtnText>{translate('language')}</NavBtnText>
        </NavWrapper> */}
        {/* <NavBottomBtn
            title={translate('Contact Us')}
            icon={'fluent:person-support-24-regular'}
          />
          <NavBottomBtn title={translate('FAQ')} icon={'octicon:question-24'} />
          <NavBottomBtn title={translate('Download')} icon="solar:download-linear" /> */}
        {/* <LanguagePopover
            CustomBtn={<NavBottomBtn icon="octicon:globe-24" />}
            PopoverProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              disabledArrow: true,
            }}
          /> */}
      </Stack>
    </Box>
  );
}

const NavBottomBtn = ({ icon, image, title, onClick = () => {} }) => (
  <NavWrapper onClick={onClick}>
    {icon ? <Iconify icon={icon} sx={{ width: '25px', height: '25px !important' }} /> : <></>}
    {image ? (
      <NavImage>
        <Image
          src={image}
          sx={{
            mx: 'auto',
            cursor: 'pointer',
            '& img': {
              maxHeight: '25px',
            },
          }}
        />
      </NavImage>
    ) : (
      <></>
    )}
    <NavBtnText>{title}</NavBtnText>
  </NavWrapper>
);
